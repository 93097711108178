import React from "react";
// Pages
import Hero from "./sections/hero";
import Nav from "./sections/nav";
import Modele from "./sections/modele";
import Cta from "./sections/cta";
import News from "./sections/news";
import Contact from "./sections/contact";
import Newsletter from "./sections/newsletter";
import Footer from "./sections/footer";
import Lh from "./sections/lh";

function Home() {
    return (
        <>
            <Nav />
            <Hero />
            <Modele />
            <Cta />
            {/* <News /> */}
            <Contact />
            <Newsletter />
            <Footer />
            <Lh />
        </>
    );
}

export default Home;
