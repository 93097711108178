import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// Assets
import { boatsDatabase } from "../../tempDbBateaux";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

// Firebase
import { db } from "../../firebase/firebase";

// Pages
import Nav from "../home/sections/nav";
import Modele from "../home/sections/modele";
import News from "../home/sections/news";
import Contact from "../home/sections/contact";
import Newsletter from "../home/sections/newsletter";
import Footer from "../home/sections/footer";
import Lh from "../home/sections/lh";

// CONSTANTE
const COLLECTION = "Boats";

function Boat() {
    const { id } = useParams();

    // Hooks
    const [boat, setBoat] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        db.collection(COLLECTION)
            .doc(id)
            .get()
            .then(doc => {
                setBoat(doc.data());
                setLoading(false);
            });
    }, []);

    return (
        !loading && (
            <>
                <Nav />
                <div className="hero__boat">
                    <h2>{boat.brand + " " + boat.model}</h2>
                </div>
                <div className="boat__container container">
                    <div className="boat__galleryContent__container ">
                        <div className="boat__gallery ">
                            <Carousel onClickItem emulateTouch swipeable showStatus={false}>
                                {boat &&
                                    boat.image.map(img => (
                                        <div>
                                            <img src={img} />
                                        </div>
                                    ))}
                            </Carousel>
                        </div>
                        <div className="boat__description ">
                            <h3>Description</h3>
                            <div>{boat.description}</div>
                            <br />
                            <div>Marque : {boat.brand}</div>
                            <div>
                                Modèle : {boat.model} {boat.length}
                            </div>
                            <div>État : {boat.new ? "Oui" : "Non"}</div>
                            <div>Longueur : {boat.length}</div>
                            <div>Nb de passagers : {boat.passengers}</div>
                            <div>Canadien : {boat.canadian ? "Oui" : "Non"}</div>
                        </div>
                    </div>
                </div>
                <div className="boatMedia">
                    <div className="boatMedia__card">
                        <a href="tel:5142828484">
                            <FaPhone />
                        </a>
                        <a href="tel:5142828484" className="boatMedia__card__cta">
                            APPELER 514.282.8484
                        </a>
                    </div>
                    <div className="boatMedia__card">
                        <a href="mailto:info@galeon.ca">
                            <FaEnvelope />
                        </a>
                        <a href="mailto:info@galeon.ca" className="boatMedia__card__cta" target="_blank">
                            ÉCRIVEZ-NOUS
                        </a>
                    </div>
                    <div className="boatMedia__card">
                        <a
                            target="_blank"
                            href="https://www.google.ca/maps/place/50+62e+Av,+%C3%8Ele-aux-Noix,+QC+J0J+1G0/@45.1277966,-73.272381,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc9901c234be58b:0x620466f6415685b8!8m2!3d45.1277928!4d-73.2701923"
                        >
                            <FaMapMarkerAlt />
                        </a>
                        <a
                            target="_blank"
                            href="https://www.google.ca/maps/place/50+62e+Av,+%C3%8Ele-aux-Noix,+QC+J0J+1G0/@45.1277966,-73.272381,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc9901c234be58b:0x620466f6415685b8!8m2!3d45.1277928!4d-73.2701923"
                            className="boatMedia__card__cta"
                        >
                            VISITEZ-NOUS{" "}
                        </a>
                    </div>
                </div>
                <Modele />
                <News />
                <Contact />
                <Newsletter />
                <Footer />
                <Lh />
            </>
        )
    );
}

export default Boat;
