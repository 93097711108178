import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// MUI
import Slide from "@material-ui/core/Slide";

// Firebase
import { db } from "../../firebase/firebase";

// Pages
import Hero from "./sections/boatsHero";
import Nav from "../home/sections/nav";
import Contact from "../home/sections/contact";
import Newsletter from "../home/sections/newsletter";
import Footer from "../home/sections/footer";
import Lh from "../home/sections/lh";

const COLLECTION = "Boats";
const SITE = "galeon";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Loads the page boats
function Boats() {
    // Hooks
    const [boats, setBoats] = useState([]);

    useEffect(() => {
        const items = [];
        db.collection(COLLECTION)
            .where("isDeleted", "==", false)
            .where("site", "array-contains", SITE)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    items.push(doc.data());
                });
                setBoats(items);
            });
    }, []);
    console.log(boats);

    // to handle change in html depending on width of site
    const [siteWidth, setSiteWidth] = useState(window.innerWidth);

    const handleSize = () => {
        setSiteWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleSize);

    // keep history in the DOM
    const hist = useHistory();
    const [open, setOpen] = useState(false);

    return (
        <>
            {siteWidth > 600 ? (
                <div>
                    <Nav />
                    <Hero />
                    <div className="boats__products container">
                        <div className="boats__sortList">
                            <div className="boats__products__list">
                                {boats &&
                                    boats.length > 0 &&
                                    boats.map(b => {
                                        return (
                                            <div key={b.brand} className="boats__products__card" onClick={() => hist.push(`boat/${b.id}`)}>
                                                <img src={b.image[0]} alt="" />
                                                <div className="boats__products__card__title">{b.brand + " " + b.model}</div>
                                            </div>
                                        );
                                    })}
                                {boats.length === 0 && <div className="boats__emptySearch"></div>}
                            </div>
                        </div>
                    </div>
                    <Contact />
                    <Newsletter />
                    <Footer />
                    <Lh />
                </div>
            ) : (
                <div>
                    <div className="boats__title">
                        <h3>Bateaux à vendre</h3>
                    </div>
                    <div className="boats__products container">
                        <div className="boats__sortList">
                            <div className="boats__products__list">
                                {boats &&
                                    boats.length > 0 &&
                                    boats.map(b => {
                                        return (
                                            <div className="boats__products__card" onClick={() => hist.push(`boat/${b.id}`)}>
                                                <img src={b.image[0]} alt="" />
                                                <div className="boats__products__card__title">{b.brand + " " + b.model}</div>
                                            </div>
                                        );
                                    })}
                                {boats.length === 0 && <div className="boats__emptySearch"></div>}
                            </div>
                        </div>
                        <br /> <br />
                        <br />
                    </div>
                    <Contact />
                    <Newsletter />
                    <Footer />
                    <Lh />
                </div>
            )}
        </>
    );
}

export default Boats;
