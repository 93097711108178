import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
// Assets

function Contact() {
    // Hooks
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const sendgrid_api_key = "";
    const marine_email = "";

    const sgMail = require("@sendgrid/mail");
    sgMail.setApiKey(process.env.sendgrid_api_key);
    const msg = {
        to: marine_email,
        from: email,
        contact: name,
        subject: subject,
        message: message,
    };
    return (
        <>
            <div className="contact">
                <a className="anchor" id="contact"></a>
                <div className="contact__container container">
                    <Grid container spacing={10}>
                        <Grid item xs={12} lg={6}>
                            <div className="contact__container--left">
                                <div className="contact-title underline-title">
                                    <h3>Contactez-nous pour votre bateau Galeon</h3>
                                </div>
                                <div className="contact-text">
                                    <p>
                                        Vous ne trouvez pas ce que vous cherchez? Vous avez des questions ou des commentaires? Remplissez le
                                        formulaire ci-joint et nous vous répondrons dans les plus brefs délais.
                                    </p>
                                </div>
                                <div className="contact-cta">
                                    <a
                                        target="_blank"
                                        href="https://www.google.com/maps/place/50+62e+Av,+%C3%8Ele-aux-Noix,+QC+J0J+1G0/@45.1277966,-73.272381,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc9901c234be58b:0x620466f6415685b8!8m2!3d45.1277928!4d-73.2701923"
                                    >
                                        <button className="btn-gold-bg-black">Trouvez-nous</button>
                                    </a>
                                </div>
                            </div>
                        </Grid>
                        <Grid className="grid-display" item xs={12} lg={6}>
                            <div className="contact__container--right">
                                <form action="/">
                                    <input type="text" id="form-name" name="name" placeholder="Nom et prénom" onChange={setName} />
                                    <input type="text" id="form-email" name="email" placeholder="Courriel" onChange={setEmail} />
                                    <input type="text" id="form-object" name="object" placeholder="Sujet" onChange={setSubject} />
                                    <textarea id="form-content" name="content" placeholder="Message" onChange={setMessage} />
                                </form>
                                <div className="form-btn">
                                    <button className="btn-gold-bg-black">Envoyer</button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default Contact;
