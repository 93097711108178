import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
// Assets
import boatSale1 from "../../../assets/home/boat-sale1.png";
import boatSale2 from "../../../assets/home/boat-sale2.png";
import boatSale3 from "../../../assets/home/boat-sale3.png";

// Firebase
import { db } from "../../../firebase/firebase";

function Modele() {
    // States
    const [boats, setBoats] = useState([]);

    useEffect(() => {
        const items = [];
        const data = db
            .collection("Boats")
            .where("isDeleted", "==", false)
            .where("site", "array-contains", "galeon")
            .orderBy("createdAt", "desc")
            .limit(3)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(b => {
                    items.push(b.data());
                });
                setBoats(() => items);
            });
    }, []);

    return (
        <div className="modele">
            <a href="" className="anchor" id="modele"></a>
            <div className="modele__container container">
                <div className="modele__container--title underline-title">
                    <h3>Nos modèles en vedette</h3>
                </div>

                <div className="modele__container--sale">
                    <Grid className="grid-container" container spacing={3}>
                        {boats.map(b => {
                            return (
                                <Grid item xs={12} sm={10} lg={4}>
                                    <div className="sale">
                                        <a className="sale-img">
                                            <img src={b.image[0]} alt="boat sale" />
                                        </a>
                                        <div className="sale__info underline-article">
                                            <h5>{b.brand + " " + b.model}</h5>
                                            <a href="#contact">
                                                <button className="btn-gold-bg-white">Contactez-nous</button>
                                            </a>
                                        </div>
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
                <div className="modele__container--plus">
                    <Link to="/boats">
                        <button className="btn-white">Voir tous nos bateaux Galeon</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Modele;
