import React from "react";
import Grid from "@material-ui/core/Grid";
// Assets
import newsletterImage from "../../../assets/home/newsletter.png";

function Newsletter() {
    return (
        <>
            <div className="newsletter ">
                <div className="newsletter__container container">
                    <Grid className="grid-container" container spacing={6}>
                        <Grid item xs={12} lg={8}>
                            <div className="newsletter__container--subscribe">
                                <div className="newsletter-title underline-title">
                                    <h3>Infolettre</h3>
                                </div>
                                <div className="newsletter-email">
                                    <form action="/">
                                        <input className="newsletter-input" type="text" placeholder="Courriel" name="mail" required />
                                        <input className="btn-gold-bg-white" type="submit" value="S'INSCRIRE" />
                                    </form>
                                </div>
                            </div>
                        </Grid>
                        <Grid className="grid-flex" item xs={12} lg={4}>
                            <div className="newsletter__container--image">
                                <img src={newsletterImage} alt="" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default Newsletter;
