import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/base/scrollToTop";
// Pages
import Home from "./components/home/home";
import Boats from "./components/boats/boats";
import Boat from "./components/boat/boat";

import "./scss/App.scss";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/boats" component={Boats} />
                <Route path="/boat/:id" component={Boat} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
