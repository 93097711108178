import React from "react";
// Assets
// import heroImg from "../../assets/hero.jpg";

function Hero() {
    return (
        <>
            <div className="hero">
                <div className="container">
                    <div className="hero__title">
                        <h2>
                            L'Élégance de{" "}
                            <span>
                                <br></br>L'Innovation
                            </span>
                        </h2>
                    </div>
                    <div className="hero__cta">
                        <a href="/#modele">
                            <button className="btn-gold-bg-black">Voir nos bateaux</button>
                        </a>
                    </div>
                    <div className="hero__cta ">
                        <a href="/#contact">
                            <button className="btn-white-bg-black">Contactez-nous</button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hero;
