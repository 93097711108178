import React from "react";
import Grid from "@material-ui/core/Grid";
// Assets

function Cta() {
    return (
        <>
            <div className="cta">
                <a className="anchor" id="about"></a>
                <div className="cta__container">
                    <Grid container spacing={0}>
                        <Grid item lg={6}>
                            <div className="cta__container--left"></div>
                        </Grid>
                        <Grid item lg={6}>
                            <div className="cta__container--right container">
                                <div className="cta-title ">
                                    <h3 className="underline-title">Notre Promesse</h3>
                                </div>
                                <div className="cta-text">
                                    <p>
                                    Chaque yacht Galeon est une pièce d'excellence artisanale et grâce à une attention inégalée aux détails, à une technologie de pointe et à de superbes designs, Galéon est devenue l'un des principaux constructeurs de bateaux européens. 
                                    </p>
                                    <p>Avec plus de 30 ans d'expérience dans le secteur et des milliers de clients satisfaits du monde entier, vous pouvez être sûr que votre prochain yacht Galeon répondra à toutes vos attentes.</p>
                                </div>
                                <div className="cta-btn">
                                    <a href="/#contact">
                                        <button className="btn-gold-bg-black">Contactez-nous</button>
                                    </a>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default Cta;
