import React from "react";
import Grid from "@material-ui/core/Grid";
// Assets
import boatNews1 from "../../../assets/home/news1.png";
import boatNews2 from "../../../assets/home/news2.png";

function News() {
    return (
        <>
            <div className="news">
                <div className="news__container container">
                    <div className="news__container--header underline-title">
                        <h3>Les nouvelles du Galeon</h3>
                    </div>
                    <div className="news__container--articles">
                        <Grid className="grid-container" container spacing={3}>
                            <Grid className="grid-container" item xs={12} sm={10} lg={6}>
                                <div className="articles">
                                    <a href="/" className="news-img">
                                        <img src={boatNews1} alt="Galeon 325 GTO" />
                                    </a>
                                    <div className="news-info underline-article">
                                        <h5>Explorez le nouveau Galeon 325 GTO</h5>
                                        {/* <button className="btn-gold-bg-white btn-lireplus">Lire plus</button> */}
                                    </div>
                                </div>
                            </Grid>
                            <Grid className="grid-container" item xs={12} sm={10} lg={6}>
                                <div className="articles">
                                    <a href="/" className="news-img">
                                        <img src={boatNews2} alt="Innovation Galeon 2021" />
                                    </a>
                                    <div className="news-info underline-article">
                                        <h5>Les innovations du Galeon 2021</h5>
                                        {/* <button className="btn-gold-bg-white btn-lireplus">Lire plus</button> */}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    {/* <div className="news__container--plus">
                        <button className="btn-white">Lire d'autres nouvelles</button>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default News;
