import React from "react";
// Assets

function Hero() {
    return (
        <>
            <div className="boatsHero">
                <h2 className="underline-title">Nos bateaux</h2>
            </div>
        </>
    );
}

export default Hero;
