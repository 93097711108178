import React from "react";

function Lh() {
    return (
        <div className="lh">
            <span className="lh-text">
                Créé par:{" "}
                <a href="https://leadhouse.ca" target="_blank">
                    LEADHOUSE
                </a>
            </span>
        </div>
    );
}

export default Lh;
