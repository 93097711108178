import serviceConfig from "./firebaseConfig.json";

import firebase from "firebase";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

firebase.initializeApp(serviceConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
