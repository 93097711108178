import React from "react";
import Grid from "@material-ui/core/Grid";
// Assets
import goldLogo from "../../../assets/logo/galeon-logo-gold.png";
import { FaFacebookMessenger, FaInstagram, FaTwitterSquare } from "react-icons/fa";

function Footer() {
    return (
        <>
            <div className="footer">
                <div className="footer__container container">
                    <Grid className="bottom-border" container spacing={0}>
                        <Grid className="grid-center" item xs={6} sm={4}>
                            <div className="footer__container--nav">
                                <div className="footer-nav-title">
                                    <h6>Liens</h6>
                                </div>
                                <div className="footer-nav-link">
                                    <a href="/boats">
                                        <span>Bateaux</span>
                                    </a>
                                    <a href="/#contact">
                                        <span>Contact</span>
                                    </a>
                                    {/* <a href="/#about">
                                        <span>Nouvelles</span>
                                    </a> */}
                                </div>
                            </div>
                        </Grid>
                        <Grid className="grid-center" item xs={6} sm={4}>
                            <div className="footer__container--logo">
                                <a href="/">
                                    <img src={goldLogo} alt="gold logo" />
                                </a>
                            </div>
                        </Grid>
                        <Grid className="grid-center" item xs={6} sm={4}>
                            <div className="footer__container--info">
                                <div className="footer-info-title">
                                    <h6>Suivez-nous</h6>
                                </div>
                                <div className="footer-info-socials">
                                    <a href="/">
                                        <FaFacebookMessenger></FaFacebookMessenger>
                                    </a>
                                    <a href="/">
                                        <FaInstagram></FaInstagram>
                                    </a>
                                </div>
                                <div className="footer-info-contact">
                                    <a
                                        target="_blank"
                                        href="https://www.google.com/maps/place/50+62e+Av,+%C3%8Ele-aux-Noix,+QC+J0J+1G0/@45.1277966,-73.272381,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc9901c234be58b:0x620466f6415685b8!8m2!3d45.1277928!4d-73.2701923"
                                    >
                                        <span>
                                            50 62e avenue <br></br> Île-aux-noix, J1J 1G0
                                        </span>
                                    </a>
                                    <a href="tel:5142828484">
                                        <span>514.282.8484</span>
                                    </a>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default Footer;
