// Assets

export const boatsDatabase = [
    {
        id: "AbsoluteYachts52Navetta",
        nom: "Absolute Yachts 52 Navetta",
        image: [
            "https://picsum.photos/1000/500",
            "https://picsum.photos/200/300",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
        ],
        marque: "Absolute Yachts",
        modele: "52 Navetta",
        description:
            "LE PLAISIR DE NAVIGUER, DE VIVRE SUR LA MER Cinquante-deux pieds de pure innovation absolue, offrant un confort et une élégance inégalés tout en harmonie avec la mer. Ce bateau offre une habitabilité nettement supérieure à celle trouvée sur d'autres yachts de taille similaire, sans renoncer à la performance ou à la fiabilité.",
        annee: 2020,
        prix: 1500000,
        condition: "new",
        lht: 61.1,
        longueurCoque: 52,
        largeurCoque: 15.3,
        coque: "ft",
        nbPassagers: 14,
        reservoirCarburant: 529,
        reservoirEau: 158,
        poids: 59000,
        capacite: 0,
        motorisation: "Double",
        categorie: "B",
        nbHeure: 0,
        fuel: "Diésel",
        canadien: true,
        numeroInventaire: 4372,
        condition: "Neuf",
    },
    {
        id: "AbsoluteYachts64Navetta",
        nom: "Absolute Yachts 64 Navetta",
        image: [
            "https://picsum.photos/1000/500",
            "https://picsum.photos/200/300",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
        ],
        marque: "Absolute Yachts",
        modele: "64 Navetta",
        description:
            "LE PLAISIR DE NAVIGUER, DE VIVRE SUR LA MER Cinquante-deux pieds de pure innovation absolue, offrant un confort et une élégance inégalés tout en harmonie avec la mer. Ce bateau offre une habitabilité nettement supérieure à celle trouvée sur d'autres yachts de taille similaire, sans renoncer à la performance ou à la fiabilité.",
        annee: 2019,
        prix: 2000000,
        condition: "new",
        lht: 61.1,
        longueurCoque: 64,
        largeurCoque: 15.3,
        coque: "ft",
        nbPassagers: 14,
        reservoirCarburant: 529,
        reservoirEau: 158,
        poids: 59000,
        capacite: 0,
        motorisation: "Double",
        categorie: "B",
        nbHeure: 0,
        fuel: "Diésel",
        canadien: true,
        numeroInventaire: 4372,
        condition: "Neuf",
    },
    {
        id: "AbsoluteYachts48Navetta",
        nom: "Absolute Yachts 48 Navetta",
        image: [
            "https://picsum.photos/1000/500",
            "https://picsum.photos/200/300",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
        ],
        marque: "Absolute Yachts",
        modele: "48 Navetta",
        description:
            "LE PLAISIR DE NAVIGUER, DE VIVRE SUR LA MER Cinquante-deux pieds de pure innovation absolue, offrant un confort et une élégance inégalés tout en harmonie avec la mer. Ce bateau offre une habitabilité nettement supérieure à celle trouvée sur d'autres yachts de taille similaire, sans renoncer à la performance ou à la fiabilité.",
        annee: 2018,
        prix: 1250000,
        condition: "new",
        lht: 61.1,
        longueurCoque: 48,
        largeurCoque: 15.3,
        coque: "ft",
        nbPassagers: 14,
        reservoirCarburant: 529,
        reservoirEau: 158,
        poids: 59000,
        capacite: 0,
        motorisation: "Double",
        categorie: "B",
        nbHeure: 0,
        fuel: "Diésel",
        canadien: true,
        numeroInventaire: 4372,
        condition: "Neuf",
    },
    {
        id: "AbsoluteYachts62Fly",
        nom: "Absolute Yachts 62 Fly",
        image: [
            "https://picsum.photos/1000/500",
            "https://picsum.photos/200/300",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
        ],
        marque: "Absolute Yachts",
        modele: "62 Fly",
        description:
            "LE PLAISIR DE NAVIGUER, DE VIVRE SUR LA MER Cinquante-deux pieds de pure innovation absolue, offrant un confort et une élégance inégalés tout en harmonie avec la mer. Ce bateau offre une habitabilité nettement supérieure à celle trouvée sur d'autres yachts de taille similaire, sans renoncer à la performance ou à la fiabilité.",
        annee: 2021,
        prix: 1750000,
        condition: "new",
        lht: 61.1,
        longueurCoque: 62,
        largeurCoque: 15.3,
        coque: "ft",
        nbPassagers: 14,
        reservoirCarburant: 529,
        reservoirEau: 158,
        poids: 59000,
        capacite: 0,
        motorisation: "Double",
        categorie: "B",
        nbHeure: 0,
        fuel: "Diésel",
        canadien: true,
        numeroInventaire: 4372,
        condition: "Neuf",
    },
    {
        id: "AbsoluteYachts47Fly",
        nom: "Absolute Yachts 47 Fly",
        image: [
            "https://picsum.photos/1000/500",
            "https://picsum.photos/200/300",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
        ],
        marque: "Absolute Yachts",
        modele: "47 Fly",
        description:
            "LE PLAISIR DE NAVIGUER, DE VIVRE SUR LA MER Cinquante-deux pieds de pure innovation absolue, offrant un confort et une élégance inégalés tout en harmonie avec la mer. Ce bateau offre une habitabilité nettement supérieure à celle trouvée sur d'autres yachts de taille similaire, sans renoncer à la performance ou à la fiabilité.",
        annee: 2021,
        prix: 1000000,
        condition: "new",
        lht: 61.1,
        longueurCoque: 47,
        largeurCoque: 15.3,
        coque: "ft",
        nbPassagers: 14,
        reservoirCarburant: 529,
        reservoirEau: 158,
        poids: 59000,
        capacite: 0,
        motorisation: "Double",
        categorie: "B",
        nbHeure: 0,
        fuel: "Diésel",
        canadien: true,
        numeroInventaire: 4372,
        condition: "Neuf",
    },
    {
        id: "Aquila44",
        nom: "Aquila 44",
        image: [
            "https://picsum.photos/1000/500",
            "https://picsum.photos/200/300",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
        ],
        marque: "Aquila",
        modele: "44",
        description:
            "LE PLAISIR DE NAVIGUER, DE VIVRE SUR LA MER Cinquante-deux pieds de pure innovation absolue, offrant un confort et une élégance inégalés tout en harmonie avec la mer. Ce bateau offre une habitabilité nettement supérieure à celle trouvée sur d'autres yachts de taille similaire, sans renoncer à la performance ou à la fiabilité.",
        annee: 2021,
        prix: 850000,
        condition: "new",
        lht: 61.1,
        longueurCoque: 44,
        largeurCoque: 15.3,
        coque: "ft",
        nbPassagers: 14,
        reservoirCarburant: 529,
        reservoirEau: 158,
        poids: 59000,
        capacite: 0,
        motorisation: "Double",
        categorie: "B",
        nbHeure: 0,
        fuel: "Diésel",
        canadien: true,
        numeroInventaire: 4372,
        condition: "Neuf",
    },
    {
        id: "Aquila32",
        nom: "Aquila 32",
        image: [
            "https://picsum.photos/1000/500",
            "https://picsum.photos/200/300",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
        ],
        marque: "Aquila",
        modele: "32",
        description:
            "LE PLAISIR DE NAVIGUER, DE VIVRE SUR LA MER Cinquante-deux pieds de pure innovation absolue, offrant un confort et une élégance inégalés tout en harmonie avec la mer. Ce bateau offre une habitabilité nettement supérieure à celle trouvée sur d'autres yachts de taille similaire, sans renoncer à la performance ou à la fiabilité.",
        annee: 2021,
        prix: 500000,
        condition: "new",
        lht: 61.1,
        longueurCoque: 32,
        largeurCoque: 15.3,
        coque: "ft",
        nbPassagers: 14,
        reservoirCarburant: 529,
        reservoirEau: 158,
        poids: 59000,
        capacite: 0,
        motorisation: "Double",
        categorie: "B",
        nbHeure: 0,
        fuel: "Diésel",
        canadien: true,
        numeroInventaire: 4372,
        condition: "Neuf",
    },
    {
        id: "Aquila32",
        nom: "Aquila 32",
        image: [
            "https://picsum.photos/1000/500",
            "https://picsum.photos/200/300",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
        ],
        marque: "Aquila",
        modele: "32",
        description:
            "LE PLAISIR DE NAVIGUER, DE VIVRE SUR LA MER Cinquante-deux pieds de pure innovation absolue, offrant un confort et une élégance inégalés tout en harmonie avec la mer. Ce bateau offre une habitabilité nettement supérieure à celle trouvée sur d'autres yachts de taille similaire, sans renoncer à la performance ou à la fiabilité.",
        annee: 2021,
        prix: 500000,
        condition: "new",
        lht: 61.1,
        longueurCoque: 32,
        largeurCoque: 15.3,
        coque: "ft",
        nbPassagers: 14,
        reservoirCarburant: 529,
        reservoirEau: 158,
        poids: 59000,
        capacite: 0,
        motorisation: "Double",
        categorie: "B",
        nbHeure: 0,
        fuel: "Diésel",
        canadien: true,
        numeroInventaire: 4372,
        condition: "Neuf",
    },
    {
        id: "Aquila32",
        nom: "Aquila 32",
        image: [
            "https://picsum.photos/1000/500",
            "https://picsum.photos/200/300",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
            "https://picsum.photos/1000/500",
        ],
        marque: "Aquila",
        modele: "32",
        description:
            "LE PLAISIR DE NAVIGUER, DE VIVRE SUR LA MER Cinquante-deux pieds de pure innovation absolue, offrant un confort et une élégance inégalés tout en harmonie avec la mer. Ce bateau offre une habitabilité nettement supérieure à celle trouvée sur d'autres yachts de taille similaire, sans renoncer à la performance ou à la fiabilité.",
        annee: 2021,
        prix: 500000,
        condition: "new",
        lht: 61.1,
        longueurCoque: 32,
        largeurCoque: 15.3,
        coque: "ft",
        nbPassagers: 14,
        reservoirCarburant: 529,
        reservoirEau: 158,
        poids: 59000,
        capacite: 0,
        motorisation: "Double",
        categorie: "B",
        nbHeure: 0,
        fuel: "Diésel",
        canadien: true,
        numeroInventaire: 4372,
        condition: "Usagé",
    },
];
