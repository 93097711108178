import React, { useState } from "react";
import { MdMenu } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// Assets
import Logo from "../../../assets/logo/galeon-yachts-logo-white.png";

function Nav() {
    const [siteWidth, setSiteWidth] = useState(window.innerWidth);
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };

    const handleSize = () => {
        setSiteWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleSize);

    return (
        <>
            {siteWidth > 1024 ? (
                <div className="nav">
                    <div className="nav-container">
                        <div className="nav__logo">
                            <Link to="/">
                                <img src={Logo} alt="logo" />
                            </Link>
                        </div>
                        <div className="nav__menu">
                            <Link to="/boats">
                                <span>Bateaux</span>
                            </Link>
                            <a href="/#about">
                                <span>À Propos</span>
                            </a>
                            <a href="/#contact">
                                <span>Contact</span>
                            </a>
                        </div>
                        <div className="nav__cta">
                            <Link to="/#contact" className="nav__menu btn-gold-bg-black">
                                ACHETER
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="nav">
                    <div className="nav-container">
                        <div className="nav__logo">
                            <Link to="/">
                                <img src={Logo} alt="logo" />
                            </Link>
                        </div>
                        <div className="hbg-menu" onClick={handleClick}>
                            {clicked ? <MdClose /> : <MdMenu />}
                        </div>
                        <div className={clicked ? "nav__menu active" : "nav__menu"}>
                            <Link to="/boats">
                                <span>Bateaux</span>
                            </Link>
                            <a href="/#about" onClick={handleClick}>
                                <span>À Propos</span>
                            </a>
                            <a href="/#contact" onClick={handleClick}>
                                <span>Contact</span>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Nav;
